body {
  background-color: #F8F6F4;
}
* {
  font-family: 'Caveat', cursive;
  font-family: 'Quicksand', sans-serif;
}

input {
  border: 0;
  border-radius: 5px;
}

textarea {
  border: 0;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  max-width: 100%;
}

h1 {
  padding: 0px;
  margin: 0px;
}

.home {
  display: flex;
  justify-content: space-between;
}

.navbar {
  background-color: #D2E9E9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
}

.nav-links {
  display: flex;
}

.nav-link {
  display: block;
  color: white;
  text-decoration: none;
  padding: 14px 16px;
}

.addItemPanel {
  width: 40%;
}


.itemListPanel {
  width: 50%;
  padding-right: 20px;
}

ul {
  list-style-type: none;
  text-align: center;
}


.listItem {
  background-color: white;
  margin: 10px;
  padding: 20px;
  border-radius: 25px;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10%;
  margin-top: 10px;
  width: 100px;
}

#pfp {
  width: 150px;
  border-radius: 50%;
}

.about {
  display:flex;
  justify-content: space-around;
}

.about-me {
  width: 45%;
}

.about-project {
  width: 45%;
}

.queryComponent {
  background-color: red;
  text-align: left;
  background-color: white;
  margin: 10px;
  padding: 20px;
  border-radius: 25px;
}

.filters {
  display: flex;
}
@media (max-width: 600px) {
  .about {
    flex-direction: column;
  }

  .about-me {
    width: 100%;
  }

  .about-project {
    width: 100%;
  }

  .home {
    flex-direction: column;
  }

  .addItemPanel  {
    width: 100%;
  }

  .itemListPanel {
    width: 100%;
  }
}


button {
  margin: 20px;
  padding: 10px;
  background-color: white;
  border: 0;
  border-radius: 5%;
}

button:hover {
  background-color: #d9d9d8;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: #C4DFDF;
}

#root > div > div > div.itemListPanel > button {
  margin-left: 0px;
}

#root > div > div > div.addItemPanel > div > form > div > button:nth-child(1) {
  margin-left: 0px;
}

#root > div > div > div.itemListPanel > div {
  text-align: center;
  margin: 10px;
  padding: 20px;
  border-radius: 25px;
}
